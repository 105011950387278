import React from 'react';
import clsx from 'clsx';
import { parse } from 'query-string';
import { makeStyles } from '@material-ui/core/styles';
import { Box, List, ListItem, Grid, Typography } from '@material-ui/core';
import { SectionAlternate, CardBase } from 'components/organisms';
import { Hero,Twistfetch, Genomicfetch, Chapterfetch, Isohelixfetch, Jnmedsysfetch, Illuminiafetch, Fluidigmfetch, Covarisfetch, Caredxfetch, Genolutionfetch, Bmsfetch } from './components';


const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    section: {
        '& .section-alternate__content': {
            paddingTop: 0,
            marginTop: theme.spacing(-5),
            position: 'relative',
            zIndex: 1,
        },
        '& .card-base__content': {
            padding: theme.spacing(2),
            [theme.breakpoints.up('md')]: {
                padding: theme.spacing(3),
            },
        },
    },
    menu: {
        height: 'auto',
    },
    list: {
        display: 'inline-flex',
        overflow: 'auto',
        flexWrap: 'nowrap',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            flexDirection: 'column',
            marginRight: theme.spacing(-3),
            marginLeft: theme.spacing(-3),
        },
    },
    listItem: {
        marginRight: theme.spacing(2),
        flex: 0,
        [theme.breakpoints.up('md')]: {
            paddingRight: theme.spacing(3),
            paddingLeft: theme.spacing(3),
            borderLeft: '2px solid transparent',
        },
    },
    listItemActive: {
        [theme.breakpoints.up('md')]: {
            borderLeft: `2px solid ${theme.palette.primary.dark}`,
        },
        '& .menu__item': {
            color: theme.palette.text.primary,
        },
    },
}));

const subPages = [

    {
        id: 'illumina',
        href: '/Genomic/?pid=illuminia',
        title: 'Illumina',
    },
    {
        id: 'genomicrange',
        href: '/Genomic/?pid=genomicrange',
        title: '10x Genomics',
    },

    {
        id: 'Fluidigm-Genomics',
        href: '/Genomic/?pid=fluidigm',
        title: 'Fluidigm  ',
    },
    {
        id: 'covaris',
        href: '/Genomic/?pid=covaris',
        title: 'Covaris',
    },
    {
        id: 'caredx',
        href: '/Genomic/?pid=caredx',
        title: 'CareDx',
    },
    {
        id: 'genolution',
        href: '/Genomic/?pid=genolution',
        title: 'Genolution',
    },
    // {
    //     id: 'isohelix',
    //     href: '/Genomic/?pid=isohelix',
    //     title: 'Isohelix',
    // },
    {
        id: 'inmedsys',
        href: '/Genomic/?pid=jnmedsys',
        title: 'Jnmedsys',
    },
    {
        id: 'twist',
        href: '/Genomic/?pid=twist',
        title: 'Twist Bioscience',
    },
    {
        id: 'bms',
        href: '/Genomic/?pid=bms',
        title: 'BMS',
    },

    {
        id: 'chapter',
        href: '/Genomic/?pid=chapter',
        title: 'ChapterDx',
    },
    



];

const TabPanel = props => {
    const { children, value, index, ...other } = props;

    return (
        <Box component="div" hidden={value !== index} {...other}>
            {value === index && children}

        </Box>
    );
};

const Genomic = (props = {}) => {
    const classes = useStyles();
    let pageId = parse(window.location.search).pid || 'genomicrange';


    return (
        <div className={classes.root}>
            <Hero />
            <SectionAlternate className={classes.section}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={3}>
                        <CardBase withShadow align="left" className={classes.menu}>
                            <List disablePadding className={classes.list}>
                                {subPages.map((item, index) => (
                                    <ListItem
                                        key={index}
                                        component={'a'}
                                        href={item.href}
                                        className={clsx(
                                            classes.listItem,
                                            pageId === item.id ? classes.listItemActive : {},
                                        )}
                                        disableGutters
                                    >

                                        <Typography
                                            variant="subtitle1"
                                            noWrap
                                            color="textSecondary"
                                            className="menu__item"
                                        >
                                            {item.title}
                                        </Typography>
                                    </ListItem>
                                ))}
                            </List>
                        </CardBase>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <CardBase withShadow align="left">
                            <TabPanel value={pageId} index={'illuminia'}>


                                <Illuminiafetch />


                            </TabPanel>
                            <TabPanel value={pageId} index={'genomicrange'}>
                                <Genomicfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'fluidigm'}>
                                <Fluidigmfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'covaris'}>
                                <Covarisfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'caredx'}>
                                <Caredxfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'genolution'}>
                                <Genolutionfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'isohelix'}>
                                <Isohelixfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'jnmedsys'}>
                                <Jnmedsysfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'twist'}>
                                <Twistfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'bms'}>
                                <Bmsfetch />
                            </TabPanel>
                            <TabPanel value={pageId} index={'chapter'}>
                                <Chapterfetch/>
                            </TabPanel>

                        </CardBase>
                    </Grid>
                </Grid>
            </SectionAlternate>
        </div>
    );
};

export default Genomic;
